import { StyleSheet, Text, View } from 'react-native';
import { List } from 'react-native-paper';
import Image from '../assets/plant-bg.jpg';
import Papa from 'papaparse';
import React, { useState, useEffect } from 'react';

const App = () => {
    const [data, setData] = useState([]);

    useEffect(() => {
        const fetchCSVData = async () => {
            const csvUrl = 'https://docs.google.com/spreadsheets/d/e/2PACX-1vRAFdmNYC6AfBtSg6DdZvhfOg8BSb6RmwZuCR_5Dn6rPwiKo3H_tC2a2shvuO53-gO1SObvSMZ8gTkZ/pub?output=csv';
            const response = await fetch(csvUrl);
            const text = await response.text();
            const parsedData = Papa.parse(text, {
                header: true,
                delimiter: ',',
                skipEmptyLines: true,
            });
            setData(parsedData.data);
        };

        fetchCSVData();
    }, []);

    const sendMessage = async (description) => {
        if (!confirm('Are you sure you want order this tea?')) {
            return;
        }
        const webhookURL = 'https://discord.com/api/webhooks/1089247098219790336/NFItrD1s5RE4VN6sfaqy7x1-8N4wrLU7aTl4pkvEqR5dQ6r1EVfM02rmouUS5UUsgXlJ';
        const content = description;

        try {
            const response = await fetch(webhookURL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ content }),
            });

            if (!response.ok) {
                throw new Error('Failed to send message');
            }

            alert('Order sent successfully! ^.^');
        } catch (error) {
            console.error('Error sending message:', error);
        }
    };

    return (
        <View style={styles.container}>
            <div style={styles.table}>
                {data.map((row, index) => (
                    <List.Item
                        key={index}
                        titleStyle={styles.listItemTitle}
                        descriptionStyle={styles.listItemDesc}
                        title={row.Thé}
                        description={row.Goût}
                        onPress={() => sendMessage('```' + row.Thé + " | " + row.Goût + " | " + row.Hint + '```')}
                        right={props => <List.Icon {...props} icon="tea" />}
                    />
                ))}
            </div>
            <Text style={styles.credit}>Made with ❤️ @LeChateau</Text>
            <br></br>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundImage: `url(${Image})`,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    table: {
        maxWidth: 800,
        padding: 30,
        backgroundColor: 'rgba(255, 255, 255, .70)',
        borderRadius: 30,
        margin: 20,
        color: "black"
    },
    credit: {
        color: "white",
    },
    listItemTitle: {
        color: "#000",
    },
    listItemDesc: {
        color: "#333",
    },
});

export default App;